import React, { useRef, useEffect } from "react";
import JsBarcode from "jsbarcode";

const BarcodeGenerator = ({ value, format, height }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, value, {
          format: format || "CODE128", // Default format is CODE128
          displayValue: true,
          height: height || 70,
        });
      } catch (error) {
        console.error("JsBarcode Error:", error);
      }
    }
  }, [value, format]);

  return <svg ref={barcodeRef}></svg>;
};

export default BarcodeGenerator;
