import { Button } from "primereact/button";
import React, { useState, useRef, useEffect } from "react";
import { TabMenu } from "primereact/tabmenu";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import {
  getCodesGenerated,
  getDistrictResults,
  getResultDashboard,
  getResults,
  getVoterCount,
} from "../../../apis/api";
import useFetch from "../../../Hooks/useFetch";
import AdminMenuBar from "../AdminMenuBar";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import ResultDashboard from "./Dashboard";
import axios from "axios";
function Results() {
  const [isLoading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [distActiveIndex, setDistActiveIndex] = useState(0);

  const [currentTab, setCurrentTab] = useState("cg");
  const [distCurrentTab, setDistCurrentTab] = useState("Z1");

  const [resData, setResData] = useState([]);
  const [distResData, setDistResData] = useState([]);
  const [voteCount, setVoteCount] = useState(0);
  const [voterCount, setVoterCount] = useState(0);
  const [districtVoterCount, setDistrictVotercount] = useState(0);

  const [totalVoterCount, setTotalVoterCount] = useState(0);
  const [codesGenerated, setcodesGenerated] = useState([]);
  const [totalcodesGenerated, settotalcodesGenerated] = useState(0);
  console.log(currentTab, "kjhgfghjkjgfgh");
  const { data, loading, err, statusCode } = useFetch(
    "GET",
    `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/all?key=${currentTab}`
  );
  console.log(data, loading, err, statusCode, "kjhgfghjkjgfgh");
  const toast = useRef(null);

  // console.log(data, loading, err, statusCode, "gfghjjjjjjjjjjjjjjjjjj");
  // if (statusCode) {
  //   console.log("status", statusCode);
  // // }
  // const addToken = async () => {

  // };
  // if (statusCode === 401) {
  //   // window.location.replace("/");
  //   // addToken();
  // }

  useEffect(() => {
    console.log(currentTab, 'currentTab')
    if (currentTab === "db") {
      getResultDashboard()
        .then((res) => {
          let totalVotesCount = 0;
          let totalVotersCount = 0;

          res?.forEach((item) => {
            totalVotesCount += Number(item?.total_votes);
            totalVotersCount += Number(item?.total_voters);
          });
          console.log(res, "called");
          setResData(res);
          setVoteCount(totalVotesCount);
          setVoterCount(totalVotersCount);
        })
        .catch((err) => {
          console.log("error", err);
        });
      getDistrictResults()
        .then((res) => {
          let totalDistrictVotersCount = 0;
          res?.forEach((item) => {
            totalDistrictVotersCount += Number(item?.total_voters);
          console.log(item, 'cjekqbjdvd');
          });
setDistrictVotercount(totalDistrictVotersCount)
          setDistResData(res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else if( currentTab === "cg"){
    getCodesGenerated().then((res)=>{
      console.log(res, 'ressssssssssss');
      setcodesGenerated(res.categoryCounts);
      settotalcodesGenerated(res.overallTotal);

    }).catch((err) => {
      console.log("error", err);
    });
    }else  {
      getVoterCount(currentTab).then((res) => {
        setTotalVoterCount(res.count);
      });
    }
  }, [currentTab]);

  const items = [
    // {
    //   label: "Dashboard",
    //   key: "db",
    // },
    // {
    //   label: "Small Scale Manufactures",
    //   key: "ms",
    // },
    // {
    //   label: "Large Scale Manufactures",
    //   key: "ml",
    // },
    // {
    //   label: "Small Scale Trading",
    //   key: "ts",
    // },
    // {
    //   label: "Large Scale Trading",
    //   key: "tl",
    // },
    // {
    //   label: "Small Scale Service",
    //   key: "ss",
    // },
    // {
    //   label: "Large Scale Service",
    //   key: "sl",
    // },
    // {
    //   label: "Profession",
    //   key: "pr",
    // },
    // {
    //   label: "District",
    //   key: "dc",
    // },
    // {
    //   label: "Association",
    //   key: "as",
    // },
    {
      label: "Codes Generated",
      key: "cg",  
    }
  ];
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" />
        <Column footer={voteCount} />
        {/* <Column footer={voterCount} /> */}
      </Row>
    </ColumnGroup>
  );
  const zones = [
    {
      label: "ZONE 1",
      key: "Z1",
    },
    {
      label: "ZONE 2",
      key: "Z2",
    },
    {
      label: "ZONE 3",
      key: "Z3",
    },
    {
      label: "ZONE 4",
      key: "Z4",
    },
    {
      label: "ZONE 5",
      key: "Z5",
    },
    {
      label: "ZONE 6",
      key: "Z6",
    },
    {
      label: "ZONE 7",
      key: "Z7",
    },
    {
      label: "ZONE 8",
      key: "Z8",
    },
  ];
  const DistrictData = ()=>{

  const filteredData = data?.data?.filter((item) => item.zone === distCurrentTab);
  console.log(filteredData, 'checkckckkc')
  return(
          <div>
            <DataTable showGridlines value={filteredData}>
              <Column field="user.first_name" header="First Name"></Column>
              <Column field="user.phone_number" header="Phone Number"></Column>
              <Column field="industry" header="Industry"></Column>
              <Column field="count" header="Total Votes"></Column>
            </DataTable>
          </div>
  )
}

  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          console.log(e);
          setActiveIndex(e.index);
          setCurrentTab(e.value.key);
          setLoading(true);
        }}
        className="m-3"
        model={items}
      />
      {loading && (
        <div>
          <div>Loading ...</div>
          <div>
            <ProgressSpinner className="h-1rem" strokeWidth={3} />
          </div>
        </div>
      )}
      {currentTab === "db" ? (
        <ResultDashboard
          resData={resData}
          voteCount={voteCount}
          voterCount={voterCount}
          distResData={distResData}
          districtVoterCount={districtVoterCount}
        />
        ) : currentTab === "dc" ? (
          
          <div className="p-3 m-3">
           
          <div className="voter-box " style={{ width: "20%" , marginBottom:'40px'}}>
            <h4>Total Voters:</h4>
            <h4>{totalVoterCount}</h4>
          </div>
          <TabMenu
        activeIndex={distActiveIndex}
        onTabChange={(e) => {
          console.log(e);
          setDistActiveIndex(e.index);
          setDistCurrentTab(e.value.key);
          setLoading(true);
        }}
        className="m-3 "
        model={zones}
        style={{ paddingTop:'20px',paddingBottom:'40px', width:'80%' , display:'flex'}}
      />
          {DistrictData()}           
        </div>
        ): currentTab === "cg" ? (
          
          <div className="p-3 m-3">
           
          <div className="voter-box " style={{ width: "20%" , marginBottom:'40px'}}>
            <h4>Total Codes Generated:</h4>
            <h4>{totalcodesGenerated}</h4>
          </div>
          <DataTable
          showGridlines
          value={codesGenerated}
          // footerColumnGroup={footerGroup}
          style={{ width: '60%' }} 
        >
          <Column field="membership_category" header="Industry"></Column>
          <Column field="total" header="Codes"></Column>
        </DataTable>
        </div>
        ) : (
        <div className="p-3 m-3 ">
          <div className="voter-box " style={{ width: "20%" }}>
            <h4>Total Voters:</h4>
            <h4>{totalVoterCount}</h4>
          </div>
          <DataTable showGridlines value={data.data}>
            <Column field="user.first_name" header="First Name"></Column>
            <Column field="user.phone_number" header="Phone Number"></Column>
            <Column field="industry" header="Industry"></Column>
            <Column field="count" header="Total Votes"></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
}

export default Results;
